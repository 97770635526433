<template>
  <v-container class="ma-0 pa-0">
    <a-calendar
      :value="value"
      :is-google-connection="isGoogleConnection"
      :token-data="tokenData"
      :document-type="documentType"
    />
    <br />
    <br />
    <!-- <v-row class="pl-md-6 pr-md-6 pl-0 pr-0"> -->
    <v-card-actions class="justify-center">
      <v-btn
        rounded
        class="
          perform-adk-community-create
          module-default__goto-discord
          mt-2
          pl-auto
          pr-auto
          font-weight-bold
          blue--text
        "
        width="500"
        outlined
        color="blue"
        x-large
        @click="$emit('goToCalendar', 'goToCalendar')"
        >Go to Calendar</v-btn
      >
    </v-card-actions>
  </v-container>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import ACalendar from '@/components/atoms/ACalendar.vue';

export default defineComponent({
  components: {
    ACalendar
  },
  props: {
    value: {
      required: true,
      default: () => {}
    },
    isGoogleConnection: {
      type: Boolean,
      default: false,
      required: false
    },
    tokenData: {
      type: Object,
      default: () => {}
    },
    documentType: {
      type: String,
      default: '',
      required: false
    }
  },
  setup(props) {}
});
</script>
