var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.isBadgesDisplayed)?_c('div',{staticClass:"d-flex justify-center mb-6"},[_c('v-progress-circular',{attrs:{"size":"50","indeterminate":"","color":"blue"}})],1):_vm._e(),(_vm.displayBadges)?_c('div',[_c('div',{staticClass:"text-center mb-4"},[_c('h2',[_vm._v("Cohorts")])]),_vm._l((_vm.badges),function(badge,index){return _c('v-container',{key:index,staticClass:"pa-1 align-center d-flex justify-center"},[_c('v-row',{staticClass:"d-flex justify-center ma-0 pa-0"},[_c('v-col',{staticClass:"d-flex justify-center ma-0 pa-0"},[_c('span',[(_vm.checkCurrentBadge(badge.startDate, badge.deadlineDate))?_c('v-badge',{attrs:{"offset-x":"20","offset-y":"0","color":"green","dot":""}}):_vm._e(),(_vm.checkFutureBadge(badge.startDate, badge.deadlineDate))?_c('v-badge',{attrs:{"offset-x":"20","offset-y":"0","color":"yellow","dot":""}}):_vm._e()],1),_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2 font-weight-bold",attrs:{"id":"date","small":"","depressed":"","outlined":""},on:{"click":function($event){return _vm.fetchDataTable(badge.users, badge.userList, badge.startDate, badge.deadlineDate)}}},'v-btn',attrs,false),on),[_vm._v(_vm._s(badge.guests)+" Interns")])]}}],null,true)},[_c('span',[_vm._v("Number of interns in cohort")])]),_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2 white--text font-weight-bold",attrs:{"id":"date","small":"","depressed":"","color":"green"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":"","left":""}},[_vm._v("mdi-calendar-start")]),_vm._v(_vm._s(_vm.momentFormat(badge.startDate)))],1)]}}],null,true)},[_c('span',[_vm._v("Start date of internship")])]),_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2 white--text font-weight-bold",attrs:{"id":"date","small":"","depressed":"","color":"red"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":"","left":""}},[_vm._v("mdi-calendar-end")]),_vm._v(_vm._s(_vm.momentFormat(badge.deadlineDate)))],1)]}}],null,true)},[_c('span',[_vm._v("Deadline date of internship")])])],1)],1)],1)})],2):_vm._e(),_c('v-dialog',{attrs:{"max-width":"800"},model:{value:(_vm.isShowDataTable),callback:function ($$v) {_vm.isShowDataTable=$$v},expression:"isShowDataTable"}},[_c('v-card',[_c('div',{attrs:{"color":"primary"}},[_c('v-row',{staticClass:"d-flex py-7 px-7 mb-2 align-center"},[_c('div',[_c('v-icon',{attrs:{"large":"","color":"black"}},[_vm._v("mdi-account-multiple")])],1),(_vm.dateRange)?_c('div',{staticClass:"d-flex align-center ml-3"},[_c('h2',[_vm._v(" Interns "),_c('span',[_vm._v(" ( "+_vm._s(_vm.momentFormat(_vm.dateRange.startDate))+" - "+_vm._s(_vm.momentFormat(_vm.dateRange.deadlineDate))+" ) ")])])]):_vm._e(),_c('div',{staticClass:"d-flex justify-end ml-auto align-center"},[_c('v-btn',{attrs:{"large":"","icon":""},on:{"click":_vm.closePopup}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-close")])],1)],1)])],1),_c('v-divider'),_c('v-row',{attrs:{"align":"center","justify":"center"}},[(_vm.isFetching)?_c('v-progress-circular',{staticClass:"adk-loader",attrs:{"size":"40","indeterminate":"","rounded":"","color":"green"}}):_vm._e()],1),_c('VDataTableView',{attrs:{"users-data":_vm.usersData}})],1)],1),_c('v-divider',{staticClass:"mt-6 mb-6"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }