<template>
  <v-row class="fill-height">
    <v-col>
      <v-sheet height="64">
        <v-toolbar flat>
          <v-btn
            dark
            class="mr-4 font-weight-bold white--text"
            color="blue"
            depressed
            @click="setToday"
            >See today</v-btn
          >
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon> mdi-chevron-left </v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon> mdi-chevron-right </v-icon>
          </v-btn>

          <v-toolbar-title v-if="calendar" class="ml-auto font-weight-bold">
            {{ calendar.title }}
          </v-toolbar-title>

          <v-spacer></v-spacer>
          <v-menu bottom right>
            <template #activator="{ on, attrs }">
              <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right> mdi-menu-down </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Day</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Week</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Month</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = '4day'">
                <v-list-item-title>4 days</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="600" style="position: relative">
        <div v-if="isLoad" class="overlay-loader d-flex align-center justify-center">
          <v-progress-circular color="blue" size="80" width="8" indeterminate />
        </div>
        <v-calendar
          ref="calendar"
          v-model="focus"
          height="100%"
          color="primary"
          :events="events"
          :event-color="getEventColor"
          :type="type"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
        >
          <template #day-body="{ date, day }">
            <div
              class="v-current-time"
              :class="{ first: date === day.date }"
              :style="{ top: nowY }"
            ></div>
          </template>
        </v-calendar>
      </v-sheet>
    </v-col>
    <v-dialog v-model="dialog" scrollable max-width="40vw">
      <v-card class="mx-auto pb-6" max-width="40vw">
        <v-img src="https://picsum.photos/600/110?random"></v-img>
        <div class="pa-6 white">
          <div class="my-4">
            <v-chip label outlined small class="font-weight-bold"
              ><v-icon small left>mdi-calendar</v-icon
              >{{ momentFormat(selectedEvent.start) }}</v-chip
            >
          </div>
          <div class="my-4">
            <h2>{{ selectedEvent.name }}</h2>
          </div>

          <div v-if="selectedEvent.hangoutLink" class="my-6">
            <v-btn
              large
              depressed
              class="white--text font-weight-bold"
              color="blue"
              @click="openMeetLink"
              ><v-icon left>mdi-video</v-icon>Join Google Meet</v-btn
            >
          </div>
          <div class="mt-6">
            <v-chip label outlined small class="font-weight-bold"
              ><v-icon small left>mdi-view-agenda</v-icon>Description</v-chip
            >
          </div>

          <div v-if="selectedEvent.description" class="mt-2">
            <span class="caption" style="white-space: pre">{{ selectedEvent.description }}</span>
          </div>
          <div v-else class="mt-2">
            <span class="caption">No event description</span>
          </div>

          <div class="mt-6">
            <v-chip label outlined small class="font-weight-bold"
              ><v-icon small left>mdi-map-marker</v-icon>Location</v-chip
            >
          </div>
          <div v-if="selectedEvent.location" class="mt-2">
            <span v-if="!isGoogleConnection" class="caption">{{
              selectedEvent.location.displayName
            }}</span>
            <span v-else class="caption">{{ selectedEvent.location }}</span>
          </div>
          <div v-else class="mt-2">
            <span class="caption">No location set</span>
          </div>

          <div class="mt-4">
            <v-chip label outlined small class="font-weight-bold"
              ><v-icon small left>mdi-account-group</v-icon>Guests (
              {{ selectedEvent.attendeesCount }} )</v-chip
            >
          </div>
          <div v-for="attendee in selectedEvent.attendees" :key="attendee" class="mt-2">
            <div v-if="isGoogleConnection">
              <span>
                <p class="caption ma-0 pa-0">{{ attendee.email }}</p>
              </span>
            </div>
            <div v-else>
              <span>
                <p class="caption">{{ attendee.emailAddress.address }}</p>
              </span>
            </div>
          </div>

          <div v-if="selectedEvent.organizer" class="mt-4">
            <v-chip label outlined small class="font-weight-bold"
              ><v-icon small left>mdi-account-circle</v-icon>Host</v-chip
            >
            <span>
              <p class="caption">{{ selectedEvent.organizerEmail }}</p>
            </span>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ref, watchEffect, computed, onMounted } from '@vue/composition-api';
import { useUserState, useUserActions } from '@/store';
import moment from 'moment';
import momentTimezone from 'moment-timezone';

const { getCalendarEvents } = useUserActions(['getCalendarEvents']);
const { deleteCalendarEvent } = useUserActions(['deleteCalendarEvent']);
const { refreshCalendarTokenData } = useUserActions(['refreshCalendarTokenData']);
const { fetchUser } = useUserActions(['fetchUser']);
const { user } = useUserState(['user']);

export default {
  props: {
    value: {
      required: true,
      default: () => {}
    },
    isGoogleConnection: {
      type: Boolean,
      default: false,
      required: false
    },
    tokenData: {
      type: Object,
      default: () => {}
    },
    documentType: {
      type: String,
      default: '',
      required: false
    }
  },
  setup(props, ctx) {
    const calendar = ref(null);
    const focus = ref('');
    const type = ref('week');
    const typeToLabel = {
      month: 'Month',
      week: 'Week',
      day: 'Day',
      '4day': '4 Days'
    };
    const ready = ref(false);
    const cal = computed(() => {
      return ready.value ? calendar.value : null;
    });
    const nowY = computed(() => {
      return cal.value ? `${cal.value.timeToY(cal.value.times.now)}px` : '-10px';
    });
    function getCurrentTime() {
      return cal.value ? cal.value.times.now.hour * 60 + cal.value.times.now.minute : 0;
    }
    function scrollToTime() {
      const time = getCurrentTime();
      const first = Math.max(0, time - (time % 30) - 30);
      cal.value.scrollToTime(first);
    }
    function updateTime() {
      setInterval(() => cal.value.updateTimes(), 60 * 1000);
    }
    onMounted(() => {
      ready.value = true;
      scrollToTime();
      updateTime();
    });
    const selectedEvent = ref({});
    const dialog = ref(false);
    const selectedElement = null;
    const selectedOpen = ref(false);
    const events = ref();
    const isLoad = ref(false);
    let items = [];
    const month = '';
    const year = '';
    let response = '';
    function viewDay({ date }) {
      focus.value = date;
      type.value = 'day';
    }

    function getEventColor(event) {
      return event.color;
    }

    function setToday() {
      focus.value = '';
    }

    function prev() {
      calendar.value.prev();
    }

    function next() {
      calendar.value.next();
    }

    function openMeetLink() {
      window.open(selectedEvent.value.hangoutLink, '_blank');
    }

    function showEvent({ event }) {
      dialog.value = true;
      selectedEvent.value = event;
    }
    async function updateRange() {
      try {
        isLoad.value = true;
        if (props?.isGoogleConnection) {
          items = response.data.items;
        } else {
          items = response.data.value;
        }
        const eventsData = [];
        for (let i = 0; i < items.length; i++) {
          if (props?.isGoogleConnection) {
            const first = momentTimezone(moment.utc(items[i].start.dateTime))
              .tz(momentTimezone.tz.guess())
              .format('YYYY-MM-DD HH:mm:ss');
            const second = momentTimezone(moment.utc(items[i].end.dateTime))
              .tz(momentTimezone.tz.guess())
              .format('YYYY-MM-DD HH:mm:ss');
            eventsData.push({
              id: items[i].id,
              name: items[i].summary,
              start: first,
              end: second,
              color: '#FF0000',
              htmlLink: items[i].htmlLink,
              hangoutLink: items[i].hangoutLink || null,
              description: items[i].description || null,
              attendees: items[i].attendees,
              attendeesCount: items[i].attendees?.length,
              organizer: items[i].organizer,
              organizerEmail: items[i].organizer?.email,
              timeZone: items[i].originalStartTimeZone,
              location: items[i].location
            });
          } else {
            const first = momentTimezone(moment.utc(items[i].start.dateTime))
              .tz(momentTimezone.tz.guess())
              .format('YYYY-MM-DD HH:mm:ss');
            const second = momentTimezone(moment.utc(items[i].end.dateTime))
              .tz(momentTimezone.tz.guess())
              .format('YYYY-MM-DD HH:mm:ss');
            eventsData.push({
              id: items[i].id,
              name: items[i].subject,
              start: first,
              end: second,
              color: '#EA6764',
              htmlLink: items[i].htmlLink,
              hangoutLink: items[i].hangoutLink || null,
              description: items[i].bodyPreview || null,
              attendees: items[i].attendees,
              attendeesCount: items[i].attendees?.length,
              organizer: items[i].organizer,
              organizerEmail: items[i].organizer?.emailAddress?.address,
              timeZone: items[i].originalStartTimeZone,
              location: items[i].location
            });
          }
        }
        events.value = eventsData;
        isLoad.value = false;
      } catch (e) {
        console.log(e);
        isLoad.value = false;
      }
    }

    async function deleteEvent(eventId) {
      dialog.value = false;
      isLoad.value = true;
      let accessToken;
      let google;
      if (props?.isGoogleConnection) {
        accessToken = props?.tokenData?.access_token;
        google = true;
      } else {
        accessToken = props?.tokenData?.access_token;
        google = false;
      }
      await deleteCalendarEvent({
        accessToken,
        eventId,
        google
      });
      response = await getCalendarEvents({
        public_id: props?.documentType === 'Student' ? props?.value?.data?._id?.toString() : null,
        accessToken,
        calendarData: {
          month: calendar?.value?.lastStart.month,
          year: calendar?.value?.lastStart.year
        },
        google
      });
      updateRange();
    }
    function momentFormat(date) {
      return moment(date).format('MMMM Do, YYYY @ h:mma');
    }
    watchEffect(async () => {
      if (calendar?.value?.lastStart) {
        isLoad.value = true;
        let google;
        let accessToken;
        let refreshToken;
        let connectionType;
        if (props?.isGoogleConnection) {
          google = true;
          accessToken = props?.tokenData?.access_token;
          refreshToken = props?.tokenData?.refresh_token;
          connectionType = 'google';
        } else {
          google = false;
          accessToken = props?.tokenData?.access_token;
          refreshToken = props?.tokenData?.refresh_token;
          connectionType = 'outlook';
        }
        response = await getCalendarEvents({
          public_id: props?.documentType === 'Student' ? props?.value?.data?._id?.toString() : null,
          accessToken,
          calendarData: {
            month: calendar?.value?.lastStart.month,
            year: calendar?.value?.lastStart.year,
            calendarType: calendar?.value?.type,
            day: calendar?.value?.lastStart.day,
            startDate: calendar?.value?.lastStart.date,
            endDate: calendar?.value?.lastEnd.date
          },
          google
        });
        isLoad.value = false;
        if (!response || (response.error && response.error.code === 'UNAUTHORIZED')) {
          const accessTokenData = await refreshCalendarTokenData({
            refreshToken,
            type: connectionType,
            document_id: props?.value?.data?._id?.toString(),
            document_type: props?.documentType,
            adk_type: props?.adkType
          });
          await fetchUser(localStorage.getItem('apollo-token'));
          response = await getCalendarEvents({
            public_id:
              props?.documentType === 'Student' ? props?.value?.data?._id?.toString() : null,
            accessToken: accessTokenData.data.access_token,
            calendarData: {
              month: calendar?.value?.lastStart.month,
              year: calendar?.value?.lastStart.year,
              calendarType: calendar?.value?.type,
              day: calendar?.value?.lastStart.day,
              startDate: calendar?.value?.lastStart.date,
              endDate: calendar?.value?.lastEnd.date
            },
            google
          });
        }
        updateRange();
      }
    });

    return {
      user,
      focus,
      type,
      typeToLabel,
      calendar,
      selectedEvent,
      selectedElement,
      selectedOpen,
      events,
      items,
      month,
      year,
      response,
      isLoad,
      dialog,
      viewDay,
      getEventColor,
      setToday,
      prev,
      next,
      openMeetLink,
      updateRange,
      showEvent,
      deleteEvent,
      momentFormat,
      ready,
      cal,
      nowY,
      getCurrentTime,
      scrollToTime,
      updateTime
    };
  }
};
</script>

<style lang="scss" scoped>
.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.overlay-loader {
  height: 600px;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  width: 100%;
  z-index: 10;
}
.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: '';
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}
</style>
