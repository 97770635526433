var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"module-table",staticStyle:{"position":"relative"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[(_vm.isFetching)?_c('v-progress-circular',{staticClass:"adk-loader",attrs:{"size":"40","indeterminate":"","rounded":"","color":"green"}}):_vm._e()],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.userList,"sort-by":"resource","page":_vm.itemsPerPage,"items-per-page":10},on:{"update:page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"item.first_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.first_name)+" "+_vm._s(item.last_name)+" ")]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [(item.directoryPreference && item.directoryPreference.hideEmail == true)?_c('div',_vm._l((item),function(item,index){return _c('v-icon',{key:index,staticStyle:{"font-size":"7px"},attrs:{"x-small":""}},[_vm._v("mdi-asterisk")])}),1):_c('div',[_vm._v(" "+_vm._s(item.email)+" ")])]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [(item.role === 'employer')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"font-size":"20px"},attrs:{"color":"purple"}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-box ")])]}}],null,true)},[_c('span',[_vm._v("Employer")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"font-size":"20px"},attrs:{"x-small":"","color":"green"}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-school ")])]}}],null,true)},[_c('span',[_vm._v("Intern")])])]}},{key:"item.phone_number",fn:function(ref){
var item = ref.item;
return [(item.directoryPreference && item.directoryPreference.hidePhone == true)?_c('div',_vm._l((item),function(item,index){return _c('v-icon',{key:index,staticStyle:{"font-size":"6px"},attrs:{"x-small":""}},[_vm._v("mdi-asterisk")])}),1):_c('div',[_vm._v(" "+_vm._s(item.phone_number)+" ")])]}},{key:"item.directoryPreference",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"px-5",attrs:{"icon":""},on:{"click":function($event){return _vm.getPreferenceData(item.directoryPreference)}}},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-information")])],1)]}}])}),_c('PreferencePreview',{attrs:{"is-open":_vm.showDialog,"get-data":_vm.getDialogData},on:{"toggle":function($event){_vm.showDialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }