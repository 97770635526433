<template>
  <v-container>
    <div v-if="isBadgesDisplayed" class="d-flex justify-center mb-6">
      <v-progress-circular size="50" indeterminate color="blue"></v-progress-circular>
    </div>
    <div v-if="displayBadges">
      <div class="text-center mb-4"><h2>Cohorts</h2></div>
      <v-container
        v-for="(badge, index) in badges"
        :key="index"
        class="pa-1 align-center d-flex justify-center"
      >
        <v-row class="d-flex justify-center ma-0 pa-0">
          <v-col class="d-flex justify-center ma-0 pa-0">
            <span
              ><v-badge
                v-if="checkCurrentBadge(badge.startDate, badge.deadlineDate)"
                offset-x="20"
                offset-y="0"
                color="green"
                dot
              ></v-badge>
              <v-badge
                v-if="checkFutureBadge(badge.startDate, badge.deadlineDate)"
                offset-x="20"
                offset-y="0"
                color="yellow"
                dot
              ></v-badge>
            </span>

            <v-tooltip top color="black">
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  id="date"
                  small
                  depressed
                  outlined
                  class="ml-2 font-weight-bold"
                  v-on="on"
                  @click="
                    fetchDataTable(badge.users, badge.userList, badge.startDate, badge.deadlineDate)
                  "
                  >{{ badge.guests }} Interns</v-btn
                >
              </template>
              <span>Number of interns in cohort</span>
            </v-tooltip>

            <v-tooltip top color="black">
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  id="date"
                  small
                  depressed
                  class="ml-2 white--text font-weight-bold"
                  color="green"
                  v-on="on"
                  ><v-icon x-small left>mdi-calendar-start</v-icon
                  >{{ momentFormat(badge.startDate) }}</v-btn
                >
              </template>
              <span>Start date of internship</span>
            </v-tooltip>

            <v-tooltip top color="black">
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  id="date"
                  small
                  depressed
                  class="ml-2 white--text font-weight-bold"
                  color="red"
                  v-on="on"
                  ><v-icon x-small left>mdi-calendar-end</v-icon
                  >{{ momentFormat(badge.deadlineDate) }}</v-btn
                >
              </template>
              <span>Deadline date of internship</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-dialog v-model="isShowDataTable" max-width="800">
      <v-card>
        <div color="primary">
          <v-row class="d-flex py-7 px-7 mb-2 align-center">
            <div><v-icon large color="black">mdi-account-multiple</v-icon></div>
            <div v-if="dateRange" class="d-flex align-center ml-3">
              <h2>
                Interns
                <span>
                  ( {{ momentFormat(dateRange.startDate) }} -
                  {{ momentFormat(dateRange.deadlineDate) }} )
                </span>
              </h2>
            </div>
            <div class="d-flex justify-end ml-auto align-center">
              <v-btn large icon @click="closePopup">
                <v-icon large>mdi-close</v-icon>
              </v-btn>
            </div>
          </v-row>
        </div>
        <v-divider></v-divider>
        <v-row align="center" justify="center">
          <v-progress-circular
            v-if="isFetching"
            class="adk-loader"
            size="40"
            indeterminate
            rounded
            color="green"
          ></v-progress-circular>
        </v-row>
        <VDataTableView :users-data="usersData" />
      </v-card>
    </v-dialog>
    <v-divider class="mt-6 mb-6"></v-divider>
  </v-container>
</template>

<script>
import { useUserActions } from '@/store';
import moment from 'moment';
import { onMounted, ref, computed } from '@vue/composition-api';
import VDataTableView from './VDataTableView.vue';

export default {
  components: {
    VDataTableView
  },
  props: {
    value: {
      required: true,
      type: Object
    },
    userType: {
      required: false,
      type: String,
      default: ''
    }
  },
  setup(props, ctx) {
    const { getInternshipBadge } = useUserActions(['getInternshipBadge']);
    const badges = ref([]);
    const displayBadges = ref(false);
    const isBadgesDisplayed = ref(false);
    const isShowDataTable = ref(false);
    const programIdValue = computed(() => {
      if (props.userType === 'organizer' || props.userType === 'employer') {
        return props.value.data._id.toString();
      }
      return props.value.data.program_id.toString();
    });
    onMounted(async () => {
      isBadgesDisplayed.value = true;
      const response = await getInternshipBadge({
        program_id: programIdValue.value.toString(),
        public_id: props?.value?.data?.program_id ? props?.value?.data?._id : null
      });
      badges.value = response;
      badges.value.filter(badge => badge.startDate);
      if (badges.value.length !== 0) {
        displayBadges.value = true;
        ctx.emit('internsBadge', badges);
      }
      isBadgesDisplayed.value = false;
    });

    function momentFormat(date) {
      return moment(date).format('MMM DD, YYYY');
    }

    function checkCurrentBadge(startDate, deadlineDate) {
      const currentDate = moment().format('YYYY-MM-DD');
      let status = false;
      if (moment(currentDate).isBetween(startDate, deadlineDate, null, '[]')) {
        status = true;
      }
      return status;
    }

    function checkFutureBadge(startDate, deadlineDate) {
      const currentDate = moment().format('YYYY-MM-DD');
      let status = false;
      if (startDate > currentDate) {
        status = true;
      }
      return status;
    }

    const usersData = ref();
    const dateRange = ref();
    const isFetching = ref(false);
    function fetchDataTable(users, userList, startDate, deadlineDate) {
      isFetching.value = true;
      usersData.value = '';
      const modifiedUsers = users.map(itm => ({
        ...userList.find(item => item._id === itm._id && item),
        ...itm
      }));
      usersData.value = modifiedUsers.map(user => ({
        first_name: user.firstName,
        last_name: user.lastName,
        email: user.email,
        phone_number: user.phoneNumber,
        directoryPreference: user.directoryPreferences[0]
      }));
      dateRange.value = { startDate, deadlineDate };
      isShowDataTable.value = true;
      setTimeout(() => {
        isFetching.value = false;
      }, 2000);
    }
    function closePopup() {
      usersData.value = '';
      isShowDataTable.value = false;
    }
    return {
      badges,
      displayBadges,
      isBadgesDisplayed,
      momentFormat,
      checkCurrentBadge,
      checkFutureBadge,
      isShowDataTable,
      fetchDataTable,
      usersData,
      closePopup,
      isFetching,
      dateRange
    };
  }
};
</script>
