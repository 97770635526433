













































































import { defineComponent, ref, watchEffect } from '@vue/composition-api';
import { TABLE_HEADER } from '@/components/molecules/const';

export default defineComponent({
  name: 'TableView',
  props: {
    usersData: {
      required: true,
      type: []
    }
  },
  setup(props, ctx) {
    const headers = ref(TABLE_HEADER);
    const isFetching = ref(false);
    const color = ref('');
    const itemsPerPage = ref(1);
    const userList = ref(props.usersData);
    watchEffect(() => {
      if (props.usersData) {
        userList.value = '';
        itemsPerPage.value = 1;
        userList.value = props.usersData;
      }
    });

    const getDialogData = ref();
    const showDialog = ref(false);
    function getPreferenceData(val) {
      getDialogData.value = val;
      showDialog.value = true;
    }
    return {
      headers,
      isFetching,
      userList,
      color,
      itemsPerPage,
      getDialogData,
      showDialog,
      getPreferenceData
    };
  }
});
